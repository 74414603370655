import { DateTime, Duration } from 'luxon';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import Loading from 'src/components/Loading';
import getElementsByState from 'src/components/Portfolio/PortfolioShow/helpers';
import subHeading from 'src/components/Portfolio/helpers/common';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import FlashesStore from 'src/stores/FlashesStore';
import { ACTIVE_STATE_ACTIVE } from 'src/util/constants';
import BrowserProtocol from 'src/util/history';
import { getDashboardTimeScope } from 'src/util/time';

import PortfolioPropertyMemberListControls from './PortfolioPropertyMemberListControls';
import PortfolioPropertyMemberListTable from './PortfolioPropertyMemberListTable';

const PortfolioPropertyMemberListFragment = graphql`
fragment PortfolioPropertyMemberList_viewer on Viewer
@refetchable(queryName: "PortfolioPropertyMemberListRefetchQuery")
@argumentDefinitions(
  id: {type: "ID!"},
  cursor: { type: "String" },
  count: { type: "Int"}
  start: { type: "Timestamp!" }
  finish: { type: "Timestamp!" }
) {
    portfolio(id:$id) {
    id
    externalIdentifier
    active { start finish }
    title
    propertyMembers(first: $count, after: $cursor, start: $start, finish: $finish)
    @connection(key: "PortfolioPropertyMemberList_propertyMembers"){
      edges {
        node {
          id
          externalIdentifier
          active { start finish }
          property {
            id
            title
            address {
              line1
              line2
              city
              state
              postcode
              country
            }
          }
        }
      }
      count
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
    userMembers(first: $count) {
      count
      edges {
        node {
          id
        }
      }
    }
  }
}
`;

/**
 * Description
 * @param {object} props
 * @param {object} props.viewer
 * @param {object} props.match
 * @returns {React.ReactElement} - PortfolioPropertyMemberList component
 */
function PortfolioPropertyMemberList({ viewer, match }) {
  if (!viewer) {
    return <Loading />;
  }
  const { params, location } = match || {};
  const { search } = location || {};
  const searchParams = new URLSearchParams(search);

  const start = searchParams.get('start');
  const finish = searchParams.get('finish');
  const { id } = params || {};

  if (!id) {
    return <Loading />;
  }

  const {
    data, loadNext, hasNext, isLoadingNext, refetch,
  } = usePaginationFragment(PortfolioPropertyMemberListFragment, viewer);
  const { portfolio } = data || {};
  const { title, propertyMembers, userMembers } = portfolio;
  const properties = propertyMembers?.edges?.map((edge) => {
    const { active, property } = edge.node;
    return { active, ...property };
  });
  const { edges: users } = userMembers || {};
  const firstTimeRender = useRef(true);

  const [activeState, setActiveState] = useState(ACTIVE_STATE_ACTIVE);
  const updatedFinish = DateTime.fromISO(finish).plus(Duration.fromISO('P1D')).startOf('day').toSeconds();
  const { s, f } = getDashboardTimeScope(
    DateTime.fromISO(start).toSeconds(),
    updatedFinish,
  );

  const [startDate, setStartDate] = useState(s);
  const [finishDate, setFinishDate] = useState(f);

  const updateTimespan = (selectedTimespan) => {
    if (!selectedTimespan) return;
    const { start: selectedStart, finish: selectedFinish } = selectedTimespan;
    setStartDate(selectedStart);
    setFinishDate(selectedFinish);
  };

  const doRefetch = () => {
    refetch(
      {
        id,
        start: startDate?.toSeconds(),
        finish: finishDate?.toSeconds() || startDate?.plus(Duration.fromISO('P1D')).startOf('day').toSeconds(),
      },
      {
        onComplete: (error) => {
          if (error) {
            FlashesStore.flash(FlashesStore.ERROR, error);
          } else {
            const { pathname } = location;

            const dateRange = {
              start: startDate?.toISODate(),
              finish: finishDate?.toISODate() || startDate?.plus(Duration.fromISO('P1D')).startOf('day').toISODate(),
            };

            BrowserProtocol.navigate({
              action: 'REPLACE',
              pathname,
              search: `?${Object.keys(dateRange).map((k) => [k, encodeURIComponent(dateRange[k])].join('=')).join('&')}`,
              hash: '',
              state: dateRange,
            });
          }
        },
      },
    );
  };

  useEffect(() => {
    if (!firstTimeRender.current) {
      doRefetch();
    }
  }, [startDate, finishDate]);

  useEffect(() => {
    firstTimeRender.current = false;
  }, []);

  /**
   * Loads the next set of users when the user hits the load more button.
   * @returns {void}
   */
  const loadMore = () => {
    if (!hasNext || isLoadingNext) {
      return;
    }

    loadNext(
      50,
      { onComplete: (error) => { console.log({ error }); } },
    );
  };

  const setFilter = (inputs) => {
    if (!inputs) {
      return;
    }
    setActiveState(inputs.activeState);
  };

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'portfolio.portfolio_show.portfolio_properties_list.page.title', defaultMessage: 'Enosi - Portfolio: {portfolioTitle}' }, { portfolioTitle: title });
  const breadcrumbPortfolio = intl.formatMessage({ id: 'portfolio.portfolio_show.portfolio_properties_list.portfolios.breadcrumb', defaultMessage: 'Portfolios' });
  const propertiesLabel = intl.formatMessage({ id: 'portfolio.portfolio_show.portfolio_properties_list.properties.label', defaultMessage: 'Properties' });
  const propertiesList = properties ? getElementsByState(properties, activeState) : [];
  const finalTimespan = (startDate && finishDate) ? { finish: DateTime.fromISO(finishDate), start: DateTime.fromISO(startDate) } : '';
  const headerInputs = {
    id,
    propertyCount: properties?.length,
    timespan: finalTimespan,
    userCount: users?.length,
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
      <Breadcrumbs
        breadcrumbs={[
          {
            name: breadcrumbPortfolio, path: '/portfolios',
          },
          { name: title, path: `/portfolios/${id}` },
          { name: propertiesLabel },
        ]}
      />
      <Heading
        alias={title}
        subtitle={subHeading(headerInputs)}
        context={propertiesLabel}
      />
      <PortfolioPropertyMemberListControls
        active={portfolio.active}
        activeState={activeState}
        timespan={{ start: startDate, finish: finishDate }}
        updateTimespanFunc={(selectedTimerange) => updateTimespan(selectedTimerange)}
        setFilterFunc={setFilter}
      />
      <PortfolioPropertyMemberListTable
        hasNext={hasNext}
        loadMore={loadMore}
        data={propertiesList}
        state={activeState}
      />
    </>
  );
}
export default PortfolioPropertyMemberList;

PortfolioPropertyMemberList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  viewer: PropTypes.object.isRequired,
  match: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      state: PropTypes.object,
    }),
  }).isRequired,
};
