/* eslint-disable no-case-declarations */
import Big from 'big.js';
import { DateTime } from 'luxon';
import React from 'react';

import {
  getCounterParty, getDirection, getTraderProperty,
  sumValuesByObjKeys, timestampToIsoString,
} from 'src/components/Dashboard/helpers/common';
import { CardsLabel } from 'src/enosikit/components/Chart/helpers/cards';
import {
  CARBON, DATA_AGGREGATE_BY_PORTFOLIO, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE,
  DIRECTIONS, TIME_ZONE_SYSTEM, TRADE_TYPE_COMMUNITY,
  TRADE_TYPE_CONTRACTED, TRADE_TYPE_NOMINATED, TRADE_TYPE_RESIDUAL, TRADE_TYPE_UNSPECIFIED,
  UNTRADED_ENERGY_KEY, VALUE,
} from 'src/util/constants';
import username from 'src/util/decorators/username';
import { getTradeTypeLabel } from 'src/util/i18n/helpers';
import { propertyLink } from 'src/util/links/customer';
import isNumber from 'src/util/math';
import getTradeType from 'src/util/trade';

/**
 * Sort an array of meter view cards in an opinionated fashion.
 * @param {object[]} cards
 * @returns {object[]} The sorted array.
 */
export const sortMeterViewCards = (cards) => cards.sort((a, b) => {
  const aVolume = a.buy.volume.plus(a.sell.volume);
  const bVolume = b.buy.volume.plus(b.sell.volume);

  if (aVolume !== bVolume) {
    return bVolume - aVolume;
  }

  if (a.key > b.key) {
    return 1;
  }

  return -1;
});

/**
 * Sort an array of cards in an opinionated fashion.
 * @param {object[]} cards
 * @returns {object[]} The sorted array.
 */
export const sortTradeViewCards = (cards) => cards.sort((a, b) => {
  const order = [
    TRADE_TYPE_CONTRACTED,
    TRADE_TYPE_NOMINATED,
    TRADE_TYPE_COMMUNITY,
    TRADE_TYPE_RESIDUAL,
    UNTRADED_ENERGY_KEY,
  ];
  const orderB = order.indexOf(b.tradeType);
  const orderA = order.indexOf(a.tradeType);

  if (orderA !== orderB) {
    return orderA - orderB;
  }

  const aVolume = a.buy.volume + a.sell.volume;
  const bVolume = b.buy.volume + b.sell.volume;

  if (aVolume !== bVolume) {
    return bVolume - aVolume;
  }

  if (a.key > b.key) {
    return 1;
  }

  return 0;
});

/**
 * Build a key, given a parent ID and the chart view object providing aggregate details.
 * @param {string} parentId The ID of the parent.
 * @param {object} chartView The chart view configuration.
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_PORTFOLIO} chartView.aggregateBy
 * @returns {string} A key compounding the parent ID and the aggregate by.
 */
export const buildMeterViewKey = (parentId, chartView) => {
  if (!parentId) {
    return null;
  }

  if (!chartView) {
    return parentId;
  }

  const { aggregateBy } = chartView;

  return `${parentId}_${aggregateBy}`;
};

export const converToNumber = (value) => Number(value) || 0;

/**
 * Get the ID from a meter view key.
 * @param {string} key
 * @returns {string} the ID.
 */
export const getIdFromMeterViewKey = (key) => (key.split('_')[0]);

/**
 * Build a meter view card label, given the property.
 * @param {object} property
 * @param {string} property.id
 * @param {string} property.title
 * @returns {React.ReactElement} the heading label for the card.
 */
export const buildMeterViewCardLabel = (property) => {
  if (!property) { return null; }

  const { title, id } = property;
  return (
    <div>
      {propertyLink({ id, title })}
    </div>
  );
};

/**
 * Build cards for meter view aggregated by property from the chart data.
 * @param {object} chartData The chart data to generate the meter view cards data from.
 * @returns {object[]} meter cards aggregated by property
 */
export const buildMeterViewCardsDataByProperty = (chartData) => {
  if (!chartData || Object.keys(chartData).length === 0) {
    return null;
  }
  const finalResp = {};

  Object.keys(chartData).forEach((key) => {
    const { property } = chartData[key];

    DIRECTIONS.forEach((dir) => {
      const data = chartData[key][dir];

      Object.keys(data).forEach((ts) => {
        const { value } = chartData[key][dir][ts];
        if (!isNumber(value) && !(value instanceof Big)) {
          return;
        }

        if (!finalResp[key]) {
          finalResp[key] = {
            key,
            property,
            buy: { volume: Big(0), count: 0 },
            sell: { volume: Big(0), count: 0 },
          };
        }

        const { volume, count } = finalResp[key][dir];
        finalResp[key][dir] = {
          ...finalResp[key][dir],
          volume: volume.plus(value),
          count: count + 1,
        };
      });
    });
  });

  return Object.values(finalResp);
};

/**
 * Call the appropriate method depending on the aggregation to build meter card data.
 * When viewing meter data at the portfolio aggregation, there are no cards to show.
 * Thus we return an empty array.
 * @param {object} chartData - The chart data to generate the trade cards data from.
 * @param {object} chartData.buy - The chart data buy object.
 * @param {object} chartData.sell - The chart data sell object.
 * @param {object} chartView - The chart view configuration.
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_PORTFOLIO} chartView.aggregateBy
 * @returns {object[]} - An array of meter cards data.
 */
export const buildMeterViewCardsData = (chartData, chartView) => {
  if (!chartData || Object.keys(chartData)?.length === 0 || !chartView) {
    return [];
  }

  const { aggregateBy } = chartView;
  switch (aggregateBy) {
    case DATA_AGGREGATE_BY_PORTFOLIO:
      return sortMeterViewCards([]);
    case DATA_AGGREGATE_BY_PROPERTY:
      return sortMeterViewCards(buildMeterViewCardsDataByProperty(chartData));
    default:
      console.error(`aggregate by '${aggregateBy}' is not valid for portfolio`);
      return sortMeterViewCards([]);
  }
};

/**
 * Transforms the portfolio's main data for the chart data for the meter view when
 * aggregated by portfolio.
 * @param {object} mainData
 * @returns {object} the meter view chart data, aggregated by portfolio.
 */
export const buildMeterViewChartDataByPortfolio = (mainData) => {
  const finalResp = {};
  if (!mainData
    || (Object.keys(mainData?.buy?.data)?.length === 0
      && Object.keys(mainData?.sell?.data)?.length === 0)) { return finalResp; }

  const { portfolio } = mainData;
  const { id: portfolioId } = portfolio;
  const key = buildMeterViewKey(portfolioId, { aggregateBy: DATA_AGGREGATE_BY_PORTFOLIO });
  finalResp[key] = {};

  let value = Big(0);
  let carbon = Big(0);

  // NOTE: need to come back and implement a timezone.
  const timezone = TIME_ZONE_SYSTEM;

  DIRECTIONS.forEach((dir) => {
    finalResp[key][dir] = {};
    let flags = [];

    if (mainData[dir]) {
      Object.keys(mainData[dir].data).forEach((timestamp) => {
        const { meterDataAggregates } = mainData[dir].data[timestamp];
        if (Object.keys(meterDataAggregates).length === 1) {
          const meterId = Object.keys(meterDataAggregates)[0];
          carbon = Big(meterDataAggregates[meterId].carbon);
          value = meterDataAggregates[meterId].value;
          flags = meterDataAggregates[meterId].flags;
        }
        if (Object.keys(meterDataAggregates).length > 1) {
          value = sumValuesByObjKeys(Object.values(meterDataAggregates), VALUE);
          carbon = sumValuesByObjKeys(Object.values(meterDataAggregates), CARBON);
          Object.keys(meterDataAggregates).forEach((meterId) => {
            meterDataAggregates[meterId].flags?.forEach((flag) => {
              if (flags.map((f) => f.identifier).indexOf(flag.identifier) === -1) {
                flags.push(flag);
              }
            });
          });
        }
        const timestampKey = timestampToIsoString(timestamp);
        const meterViewDataSeries = {
          timestamp: DateTime.fromISO(timestampKey, { zone: timezone }),
          value: Number(value),
          flags,
          carbon,
        };
        finalResp[key][dir][timestampKey] = meterViewDataSeries;
      });
    }
  });

  return finalResp[key];
};

/**
 * Transforms the portfolio's main data for the chart data for the meter view when
 * aggregated by property.
 * @param {object} mainData
 * @returns {object} the meter view chart data, aggregated by property.
 */
export const buildMeterViewChartDataByProperty = (mainData) => {
  const finalResp = {};

  if (!mainData) return finalResp;

  // NOTE: need to come back and implement a timezone.
  const timezone = TIME_ZONE_SYSTEM;

  Object.values(mainData.meters)?.forEach((meter) => {
    const { id: meterId, propertyId } = meter;

    const key = buildMeterViewKey(propertyId, { aggregateBy: DATA_AGGREGATE_BY_PROPERTY });
    const property = mainData.properties[propertyId];

    DIRECTIONS.forEach((dir) => {
      if (!finalResp[key]) {
        finalResp[key] = {
          buy: {},
          sell: {},
          property,
          title: property?.title,
        };
      }

      Object.keys(mainData[dir].data).forEach((timestamp) => {
        const timestampKey = timestampToIsoString(timestamp);
        const dataPoint = mainData[dir].data[timestamp];
        const { meterDataAggregates } = dataPoint;
        const { value, flags, carbon } = meterDataAggregates[meterId] || {};
        if (!flags) return;

        const finalCarbon = converToNumber(carbon);
        const finalValue = converToNumber(value);

        finalResp[key][dir][timestampKey] ||= {
          timestamp: DateTime.fromISO(timestampKey, { zone: timezone }),
          value: Big(0),
          flags: [],
          carbon: Big(0),
        };

        const datum = finalResp[key][dir][timestampKey];
        const aggregatedCarbon = Big(datum.value).plus(finalCarbon);
        const aggregatedValue = Big(datum.value).plus(finalValue);

        datum.carbon = Number(aggregatedCarbon);
        datum.value = Number(aggregatedValue);

        flags.forEach((flag) => {
          if (datum.flags.map((f) => f.identifier).indexOf(flag.identifier) === -1) {
            datum.flags.push(flag);
          }
        });
      });
    });
  });

  return finalResp;
};

/**
 * @param {object} mainData
 * @param {object} chartView
 * @returns {object} the meter view chart data.
 */
export const buildMeterViewChartData = (mainData, chartView) => {
  if (!mainData || !chartView) {
    return {};
  }

  const { aggregateBy } = chartView;
  switch (aggregateBy) {
    case DATA_AGGREGATE_BY_PORTFOLIO:
      return buildMeterViewChartDataByPortfolio(mainData);
    case DATA_AGGREGATE_BY_PROPERTY:
      return buildMeterViewChartDataByProperty(mainData);
    default:
      console.error(`aggregate by '${aggregateBy}' is not valid for portfolio`);
      return {};
  }
};

/**
 * Get the entity id. Null if not set.
 * @param {object} entity
 * @returns {string} The entity's id, falling back to a default value.
 */
export const getEntityId = (entity) => (entity?.id ? entity.id : null);

/**
 * Build a key, given a parent ID and the chart view object providing aggregate details.
 * @param {string} parentId The ID of the parent.
 * @param {object} chartView The chart view configuration.
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_PORTFOLIO} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @param {string} tradeType the type of trade.
 * @param {object} opts additional options.
 * @returns {string} A key compounding the parent ID and the aggregate by.
 */
export const buildTradeViewKey = (parentId, chartView, tradeType, opts = {}) => {
  const { aggregateBy, groupBy } = chartView || {};
  const { counterParty, counterPartyProperty } = opts || {};

  if (!tradeType) {
    return TRADE_TYPE_UNSPECIFIED;
  }

  // Community and residuals are just the parent and the trade type.
  if ([TRADE_TYPE_COMMUNITY, TRADE_TYPE_RESIDUAL].indexOf(tradeType) !== -1) {
    return [parentId, tradeType, aggregateBy, groupBy].filter(Boolean).join('_');
  }

  switch (groupBy) {
    case DATA_GROUP_BY_COUNTERPARTY:
      const counterPartyUserId = getEntityId(counterParty?.user);
      const counterPartyPropertyId = getEntityId(counterPartyProperty);
      return [parentId, tradeType, counterPartyUserId || '', counterPartyPropertyId || '', aggregateBy, groupBy].filter(Boolean).join('_');
    case DATA_GROUP_BY_TRADE_TYPE:
      return [parentId, tradeType, aggregateBy, groupBy].filter(Boolean).join('_');
    default:
      console.error(`group by '${groupBy}' are not valid for buildTradeViewKey`);
      return TRADE_TYPE_UNSPECIFIED;
  }
};

/**
 * Get the ID from a trade view key.
 * @param {string} key
 * @returns {string} the ID.
 */
export const getIdFromTradeViewKey = (key) => key.split('_')[0];

/**
 * Conslidate all the trades.
 * @param {object} tradeData
 * @returns {object} - consolidated trades.
 */
export const consolidateTrades = (tradeData) => {
  if (Object.keys(tradeData).length === 0) {
    return [];
  }
  const { buy: tradeBuy, sell: tradeSell } = tradeData || {};
  const resp = {
    buy: [
      ...Object.values(tradeBuy.contracted),
      ...Object.values(tradeBuy.nominated),
      ...Object.values(tradeBuy.community),
      ...Object.values(tradeBuy.residual),
      ...Object.values(tradeBuy.untraded),
    ],
    sell: [
      ...Object.values(tradeSell.contracted),
      ...Object.values(tradeSell.nominated),
      ...Object.values(tradeSell.community),
      ...Object.values(tradeSell.residual),
      ...Object.values(tradeSell.untraded),
    ],
  };
  return resp;
};

/**
 * Build the trade view data container.
 * @param {string} key
 * @param {string} tradeType As per proto string format. That is, `TRADE_TYPE_CONTRACTED`,
 * `TRADE_TYPE_RESIDUAL` etc.
 * @param {object} opts Options.
 * @param {object} opts.counterParty The counterparty where relevant.
 * @param {object} opts.counterPartyProperty The counterparty's property where relevant
 * _and_ available.
 * @param {object} opts.property The property where relevant.
 * @returns {object} {
 *  key, counterPartyUser, counterPartyProperty, portfolio, property, tradeType, data,
 * }
 */
export const buildTradeViewDataContainer = (
  key,
  tradeType,
  opts = { counterParty: null, counterPartyProperty: null, property: null },
) => {
  const { counterParty, counterPartyProperty, property } = opts || {};

  if (!tradeType) {
    return {
      key,
      tradeType: TRADE_TYPE_UNSPECIFIED,
      counterPartyUser: counterParty?.user,
      counterPartyProperty,
      portfolio: null,
      property,
      data: {},
    };
  }
  let label = tradeType;
  let subLabel = null;

  if (property) {
    subLabel = label;
    label = property.title;
  }
  if (counterParty?.user) {
    subLabel = username(counterParty.user) || subLabel;
    label = counterPartyProperty?.title || label;
  }

  return {
    key,
    tradeType,
    counterPartyUser: counterParty?.user,
    counterPartyProperty,
    portfolio: null,
    property,
    data: {},
    label,
    subLabel,
  };
};

/**
 * Checks whether an object have untraded data with a volume greater than zero.
 * @param {object} data - The timeseries data object.
 * @returns {boolean} - Returns true if there is a volume different from zero, otherwise false.
 */
export const hasVolume = (data) => {
  if (!data) {
    return false;
  }

  return Object.values(data).some(({ volume }) => volume !== 0);
};

/**
 * @param {object} mainData
 * @returns {object} The trade view chart data.
 */
export const buildTradeViewChartDataByPortfolioAndCounterParty = (mainData) => {
  const resp = {
    buy: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
    sell: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
  };

  if (!mainData) {
    return resp;
  }

  const timezone = TIME_ZONE_SYSTEM;
  const { meters, portfolio } = mainData;
  const { id: portfolioId } = portfolio;
  const chartView = {
    aggregateBy: DATA_AGGREGATE_BY_PORTFOLIO,
    groupBy: DATA_GROUP_BY_COUNTERPARTY,
  };

  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) {
      return;
    }

    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const timestampKey = timestampToIsoString(timestamp);
      const { tradeSetSummaries, untradedDataAggregates } = mainData[dir].data[timestamp] || {};
      if (!tradeSetSummaries) {
        return;
      }

      Object.keys(tradeSetSummaries)?.forEach((ruleId) => {
        const tradeSetSummary = tradeSetSummaries[ruleId] || {};
        const {
          meterId, type: tradeType, carbon = 0, value = 0, volume = 0,
        } = tradeSetSummary || {};

        if (!tradeType) {
          return;
        }

        const { aggregation } = meters[meterId] || {};
        if (!aggregation) {
          return;
        }

        const tradeTypeKey = getTradeType(tradeType);
        const { rules } = mainData[dir];
        const rule = rules[ruleId] || {};
        const counterParty = getCounterParty(rule, tradeType, getDirection(dir));
        const counterPartyProperty = getTraderProperty(counterParty);
        const t = resp[dir][tradeTypeKey];

        const key = buildTradeViewKey(
          portfolioId,
          chartView,
          tradeType,
          {
            counterParty,
            counterPartyProperty,
            ruleId,
          },
        );

        if (!(key in t)) {
          t[key] = buildTradeViewDataContainer(
            key,
            tradeType,
            {
              counterParty,
              counterPartyProperty,
              property: null,
            },
          );
        }

        const dataSeries = t[key].data;

        dataSeries[timestampKey] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[timestampKey].carbon).plus(carbon);
        const aggregateValueByDate = Big(dataSeries[timestampKey].value).plus(value);
        const aggregateVolumeByDate = Big(dataSeries[timestampKey].volume).plus(volume);

        dataSeries[timestampKey].carbon = Number(aggregateCarbonByDate);
        dataSeries[timestampKey].value = Number(aggregateValueByDate);
        dataSeries[timestampKey].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(carbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(value);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(volume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });

      Object.keys(untradedDataAggregates)?.forEach((meterId) => {
        const { aggregation, propertyId } = meters[meterId];
        if (!aggregation || !propertyId || !untradedDataAggregates[meterId]) {
          return;
        }
        const {
          carbon = 0, value = 0, volume = 0,
        } = untradedDataAggregates[meterId] || {};
        const finalCarbon = converToNumber(carbon);
        const finalValue = converToNumber(value);
        const finalVolume = converToNumber(volume);

        const t = resp[dir][UNTRADED_ENERGY_KEY];

        const key = buildTradeViewKey(
          portfolioId,
          chartView,
          UNTRADED_ENERGY_KEY,
          {},
        );

        if (!(key in t)) {
          t[key] = buildTradeViewDataContainer(
            key,
            UNTRADED_ENERGY_KEY,
            {},
          );
        }

        const dataSeries = t[key].data;

        dataSeries[timestampKey] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[timestampKey].carbon).plus(finalCarbon);
        const aggregateValueByDate = Big(dataSeries[timestampKey].value).plus(finalValue);
        const aggregateVolumeByDate = Big(dataSeries[timestampKey].volume).plus(finalVolume);

        dataSeries[timestampKey].carbon = Number(aggregateCarbonByDate);
        dataSeries[timestampKey].value = Number(aggregateValueByDate);
        dataSeries[timestampKey].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(finalCarbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(finalValue);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(finalVolume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });
    });
  });

  // Remove untraded datasets that have zero volume from the resp.
  // This is to ensure we do not display zero volume untraded energy datasets in
  // the chart and their corresponding cards.
  DIRECTIONS.forEach((dir) => {
    Object.keys(resp[dir][UNTRADED_ENERGY_KEY]).forEach((key) => {
      const { data } = resp[dir][UNTRADED_ENERGY_KEY][key] || {};
      if (hasVolume(data)) { return; }

      delete (resp[dir][UNTRADED_ENERGY_KEY][key]);
    });
  });

  return resp;
};

/**
 * Builds the trade view chart data by portfolio and trade type.
 * @param {object} mainData Time series data (meter, trades and untraded).
 * @returns {object} The trade view chart data grouped by trade type aggregated by portfolio.
 */
export const buildTradeViewChartDataByPortfolioAndTradeType = (mainData) => {
  const resp = {
    buy: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
    sell: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
  };

  if (!mainData) {
    return resp;
  }

  const timezone = TIME_ZONE_SYSTEM;
  const { meters, portfolio } = mainData;
  const { id: portfolioId } = portfolio;
  const chartView = { aggregateBy: DATA_AGGREGATE_BY_PORTFOLIO, groupBy: DATA_GROUP_BY_TRADE_TYPE };

  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) {
      return;
    }

    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const timestampKey = timestampToIsoString(timestamp);
      const { tradeSetSummaries, untradedDataAggregates } = mainData[dir].data[timestamp] || {};
      if (!tradeSetSummaries) {
        return;
      }

      Object.keys(tradeSetSummaries)?.forEach((ruleId) => {
        const tradeSetSummary = tradeSetSummaries[ruleId] || {};
        const {
          meterId, type: tradeType, carbon = 0, value = 0, volume = 0,
        } = tradeSetSummary || {};

        if (!tradeType) {
          return;
        }

        const { aggregation } = meters[meterId] || {};
        if (!aggregation) {
          return;
        }

        const tradeTypeKey = getTradeType(tradeType);
        const t = resp[dir][tradeTypeKey];

        const key = buildTradeViewKey(
          portfolioId,
          chartView,
          tradeType,
          {},
        );

        if (!(key in t)) {
          t[key] = buildTradeViewDataContainer(
            key,
            tradeType,
            {},
          );
        }

        const dataSeries = t[key].data;

        dataSeries[timestampKey] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[timestampKey].carbon).plus(carbon);
        const aggregateValueByDate = Big(dataSeries[timestampKey].value).plus(value);
        const aggregateVolumeByDate = Big(dataSeries[timestampKey].volume).plus(volume);

        dataSeries[timestampKey].carbon = Number(aggregateCarbonByDate);
        dataSeries[timestampKey].value = Number(aggregateValueByDate);
        dataSeries[timestampKey].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(carbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(value);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(volume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });

      Object.keys(untradedDataAggregates)?.forEach((meterId) => {
        const { aggregation, propertyId } = meters[meterId];
        if (!aggregation || !propertyId || !untradedDataAggregates[meterId]) {
          return;
        }
        const {
          carbon = 0, value = 0, volume = 0,
        } = untradedDataAggregates[meterId] || {};
        const finalCarbon = converToNumber(carbon);
        const finalValue = converToNumber(value);
        const finalVolume = converToNumber(volume);

        const t = resp[dir][UNTRADED_ENERGY_KEY];

        const key = buildTradeViewKey(
          portfolioId,
          chartView,
          UNTRADED_ENERGY_KEY,
          {},
        );

        if (!(key in t)) {
          t[key] = buildTradeViewDataContainer(
            key,
            UNTRADED_ENERGY_KEY,
            {},
          );
        }

        const dataSeries = t[key].data;

        dataSeries[timestampKey] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[timestampKey].carbon).plus(finalCarbon);
        const aggregateValueByDate = Big(dataSeries[timestampKey].value).plus(finalValue);
        const aggregateVolumeByDate = Big(dataSeries[timestampKey].volume).plus(finalVolume);

        dataSeries[timestampKey].carbon = Number(aggregateCarbonByDate);
        dataSeries[timestampKey].value = Number(aggregateValueByDate);
        dataSeries[timestampKey].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(finalCarbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(finalValue);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(finalVolume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });
    });
  });

  // Remove untraded datasets that have zero volume from the resp.
  // This is to ensure we do not display zero volume untraded energy datasets in
  // the chart and their corresponding cards.
  DIRECTIONS.forEach((dir) => {
    Object.keys(resp[dir][UNTRADED_ENERGY_KEY]).forEach((key) => {
      const { data } = resp[dir][UNTRADED_ENERGY_KEY][key] || {};
      if (hasVolume(data)) { return; }

      delete (resp[dir][UNTRADED_ENERGY_KEY][key]);
    });
  });

  return resp;
};

export const buildTradeViewChartDataByPropertyAndCounterParty = (mainData) => {
  const resp = {
    buy: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
    sell: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
  };

  if (!mainData) {
    return resp;
  }

  const timezone = TIME_ZONE_SYSTEM;
  const { meters, properties } = mainData;
  const chartView = {
    aggregateBy: DATA_AGGREGATE_BY_PROPERTY,
    groupBy: DATA_GROUP_BY_COUNTERPARTY,
  };
  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) {
      return;
    }

    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const timestampKey = timestampToIsoString(timestamp);
      const { tradeSetSummaries, untradedDataAggregates } = mainData[dir].data[timestamp] || {};
      if (!tradeSetSummaries) {
        return;
      }

      Object.keys(tradeSetSummaries)?.forEach((ruleId) => {
        const tradeSetSummary = tradeSetSummaries[ruleId] || {};
        const {
          meterId, type: tradeType, carbon = 0, value = 0, volume = 0,
        } = tradeSetSummary || {};

        if (!tradeType) {
          return;
        }

        const { aggregation, propertyId } = meters[meterId] || {};
        if (!aggregation) {
          return;
        }

        const property = mainData.properties[propertyId];
        const tradeTypeKey = getTradeType(tradeType);
        const { rules } = mainData[dir];
        const rule = rules[ruleId] || {};
        const counterParty = getCounterParty(rule, tradeType, getDirection(dir));
        const counterPartyProperty = getTraderProperty(counterParty);
        const t = resp[dir][tradeTypeKey];

        const key = buildTradeViewKey(
          propertyId,
          chartView,
          tradeType,
          {
            counterParty,
            counterPartyProperty,
          },
        );

        if (!(key in t)) {
          t[key] = buildTradeViewDataContainer(
            key,
            tradeType,
            {
              counterParty,
              counterPartyProperty,
              property,
            },
          );
        }

        const dataSeries = t[key].data;

        dataSeries[timestampKey] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[timestampKey].carbon).plus(carbon);
        const aggregateValueByDate = Big(dataSeries[timestampKey].value).plus(value);
        const aggregateVolumeByDate = Big(dataSeries[timestampKey].volume).plus(volume);

        dataSeries[timestampKey].carbon = Number(aggregateCarbonByDate);
        dataSeries[timestampKey].value = Number(aggregateValueByDate);
        dataSeries[timestampKey].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(carbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(value);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(volume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });

      Object.keys(untradedDataAggregates)?.forEach((meterId) => {
        const { aggregation, propertyId } = meters[meterId];
        if (!aggregation || !propertyId || !untradedDataAggregates[meterId]) {
          return;
        }
        const {
          carbon = 0, value = 0, volume = 0,
        } = untradedDataAggregates[meterId] || {};
        const property = properties[propertyId];
        const finalCarbon = converToNumber(carbon);
        const finalValue = converToNumber(value);
        const finalVolume = converToNumber(volume);

        const t = resp[dir][UNTRADED_ENERGY_KEY];

        const key = buildTradeViewKey(
          propertyId,
          chartView,
          UNTRADED_ENERGY_KEY,
          {},
        );

        if (!(key in t)) {
          t[key] = buildTradeViewDataContainer(
            key,
            UNTRADED_ENERGY_KEY,
            { counterParty: null, counterPartyProperty: null, property },
          );
        }

        const dataSeries = t[key].data;

        dataSeries[timestampKey] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[timestampKey].carbon).plus(finalCarbon);
        const aggregateValueByDate = Big(dataSeries[timestampKey].value).plus(finalValue);
        const aggregateVolumeByDate = Big(dataSeries[timestampKey].volume).plus(finalVolume);

        dataSeries[timestampKey].carbon = Number(aggregateCarbonByDate);
        dataSeries[timestampKey].value = Number(aggregateValueByDate);
        dataSeries[timestampKey].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(finalCarbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(finalValue);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(finalVolume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });
    });
  });

  // Remove untraded datasets that have zero volume from the resp.
  // This is to ensure we do not display zero volume untraded energy datasets in
  // the chart and their corresponding cards.
  DIRECTIONS.forEach((dir) => {
    Object.keys(resp[dir][UNTRADED_ENERGY_KEY]).forEach((key) => {
      const { data } = resp[dir][UNTRADED_ENERGY_KEY][key] || {};
      if (hasVolume(data)) { return; }

      delete (resp[dir][UNTRADED_ENERGY_KEY][key]);
    });
  });

  return resp;
};

export const buildTradeViewChartDataByPropertyAndTradeType = (mainData) => {
  const resp = {
    buy: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
    sell: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
  };

  if (!mainData) {
    return resp;
  }
  const chartView = { aggregateBy: DATA_AGGREGATE_BY_PROPERTY, groupBy: DATA_GROUP_BY_TRADE_TYPE };
  const timezone = TIME_ZONE_SYSTEM;
  const { meters, properties } = mainData;

  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) {
      return;
    }

    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const timestampKey = timestampToIsoString(timestamp);
      const { tradeSetSummaries, untradedDataAggregates } = mainData[dir].data[timestamp] || {};
      if (!tradeSetSummaries) {
        return;
      }

      Object.keys(tradeSetSummaries)?.forEach((ruleId) => {
        const tradeSetSummary = tradeSetSummaries[ruleId] || {};
        const {
          meterId, type: tradeType, carbon = 0, value = 0, volume = 0,
        } = tradeSetSummary || {};

        if (!tradeType) {
          return;
        }

        const { aggregation, propertyId } = meters[meterId] || {};
        if (!aggregation || !propertyId) {
          return;
        }

        const property = properties[propertyId];
        const tradeTypeKey = getTradeType(tradeType);
        const t = resp[dir][tradeTypeKey];

        const key = buildTradeViewKey(
          propertyId,
          chartView,
          tradeType,
          {},
        );

        if (!(key in t)) {
          t[key] = buildTradeViewDataContainer(
            key,
            tradeType,
            { counterParty: null, counterPartyProperty: null, property },
          );
        }

        const dataSeries = t[key].data;

        dataSeries[timestampKey] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[timestampKey].carbon).plus(carbon);
        const aggregateValueByDate = Big(dataSeries[timestampKey].value).plus(value);
        const aggregateVolumeByDate = Big(dataSeries[timestampKey].volume).plus(volume);

        dataSeries[timestampKey].carbon = Number(aggregateCarbonByDate);
        dataSeries[timestampKey].value = Number(aggregateValueByDate);
        dataSeries[timestampKey].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(carbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(value);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(volume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });

      Object.keys(untradedDataAggregates)?.forEach((meterId) => {
        const { aggregation, propertyId } = meters[meterId];
        if (!aggregation || !propertyId || !untradedDataAggregates[meterId]) {
          return;
        }
        const {
          carbon = 0, value = 0, volume = 0,
        } = untradedDataAggregates[meterId] || {};
        const property = properties[propertyId];
        const finalCarbon = converToNumber(carbon);
        const finalValue = converToNumber(value);
        const finalVolume = converToNumber(volume);

        const t = resp[dir][UNTRADED_ENERGY_KEY];

        const key = buildTradeViewKey(
          propertyId,
          chartView,
          UNTRADED_ENERGY_KEY,
          {},
        );

        if (!(key in t)) {
          t[key] = buildTradeViewDataContainer(
            key,
            UNTRADED_ENERGY_KEY,
            { counterParty: null, counterPartyProperty: null, property },
          );
        }

        const dataSeries = t[key].data;

        dataSeries[timestampKey] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[timestampKey].carbon).plus(finalCarbon);
        const aggregateValueByDate = Big(dataSeries[timestampKey].value).plus(finalValue);
        const aggregateVolumeByDate = Big(dataSeries[timestampKey].volume).plus(finalVolume);

        dataSeries[timestampKey].carbon = Number(aggregateCarbonByDate);
        dataSeries[timestampKey].value = Number(aggregateValueByDate);
        dataSeries[timestampKey].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(finalCarbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(finalValue);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(finalVolume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });
    });
  });

  // Remove untraded datasets that have zero volume from the resp.
  // This is to ensure we do not display zero volume untraded energy datasets in
  // the chart and their corresponding cards.
  DIRECTIONS.forEach((dir) => {
    Object.keys(resp[dir][UNTRADED_ENERGY_KEY]).forEach((key) => {
      const { data } = resp[dir][UNTRADED_ENERGY_KEY][key] || {};
      if (hasVolume(data)) { return; }

      delete (resp[dir][UNTRADED_ENERGY_KEY][key]);
    });
  });

  return resp;
};

/**
 * Builds the chart data for the trade view, given the chart view aggregation and grouping options.
 * @param {object} mainData
 * @param {object} chartView
 * @param {DATA_AGGREGATE_BY_PORTFOLIO | DATA_AGGREGATE_BY_PROPERTY} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY| DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {object} the trade view chart data.
 */
export const buildTradeViewChartData = (mainData, chartView) => {
  if (!mainData || !chartView) {
    return {};
  }

  const { aggregateBy, groupBy } = chartView;

  if ([DATA_AGGREGATE_BY_PORTFOLIO, DATA_AGGREGATE_BY_PROPERTY].indexOf(aggregateBy) === -1) {
    console.error(`aggregate by '${aggregateBy}' not valid for buildTradeViewChartData`);
    return {};
  }

  if ([DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE].indexOf(groupBy) === -1) {
    console.error(`group by '${groupBy}' not valid for buildTradeViewChartData`);
    return {};
  }

  switch (`${aggregateBy}_${groupBy}`) {
    case `${DATA_AGGREGATE_BY_PORTFOLIO}_${DATA_GROUP_BY_COUNTERPARTY}`:
      return buildTradeViewChartDataByPortfolioAndCounterParty(mainData);
    case `${DATA_AGGREGATE_BY_PORTFOLIO}_${DATA_GROUP_BY_TRADE_TYPE}`:
      return buildTradeViewChartDataByPortfolioAndTradeType(mainData);
    case `${DATA_AGGREGATE_BY_PROPERTY}_${DATA_GROUP_BY_COUNTERPARTY}`:
      return buildTradeViewChartDataByPropertyAndCounterParty(mainData);
    case `${DATA_AGGREGATE_BY_PROPERTY}_${DATA_GROUP_BY_TRADE_TYPE}`:
      return buildTradeViewChartDataByPropertyAndTradeType(mainData);

    default:
      console.error(`aggregate by '${aggregateBy}' is not valid for portfolio`);
      return {};
  }
};

/**
 * Builds the trade view acrds data.
 * @param {object} chartData The data prepared for the chart.
 * @returns {object[]} An array of trade cards data, based on the dataset in the chart.
 */
export const buildTradeViewCardsData = (chartData) => {
  const resp = {};

  if (!chartData) { return Object.values(resp); }

  DIRECTIONS.forEach((dir) => {
    Object.values(chartData[dir])?.forEach((chartDatum) => {
      const {
        counterPartyUser, counterPartyProperty, data, key, property, tradeType,
      } = chartDatum || {};
      if (!data) {
        return;
      }
      resp[key] ||= {
        key,
        tradeType,
        property,
        counterPartyUser,
        counterPartyProperty,
        buy: {
          value: 0, volume: 0, carbon: 0, count: 0,
        },
        sell: {
          value: 0, volume: 0, carbon: 0, count: 0,
        },
      };

      Object.keys(data)?.forEach((ts) => {
        const { value, volume, carbon } = data[ts] || {};
        if (!isNumber(value) || !isNumber(volume) || !isNumber(carbon)) {
          return;
        }
        resp[key][dir].value += value;
        resp[key][dir].volume += volume;
        resp[key][dir].carbon += carbon;
        resp[key][dir].count += 1;
      });
    });
  });
  return sortTradeViewCards(Object.values(resp));
};

/**
 * Build the chart data, given the main data, the chart view and whether we are in
 * meter view or trade view.
 * @param {boolean} isMeterView
 * @param {object} mainData
 * @param {object} chartView
 * @param {DATA_AGGREGATE_BY_PORTFOLIO | DATA_AGGREGATE_BY_PROPERTY} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY| DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {object} the chart view data.
 */
export const buildChartData = (isMeterView, mainData, chartView) => {
  if (isMeterView) {
    return buildMeterViewChartData(mainData, chartView);
  }
  const chartData = buildTradeViewChartData(mainData, chartView);
  return consolidateTrades(chartData);
};

/**
 * Transform the chart data into the cards data.
 * @param {boolean} isMeterView Flag if is meter view.
 * @param {object} chartData
 * @param {object} chartView
 * @param {DATA_AGGREGATE_BY_PORTFOLIO | DATA_AGGREGATE_BY_PROPERTY} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY| DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {object} The chart cards' dataset.
 */
export const buildCardsData = (isMeterView, chartData, chartView) => {
  if (isMeterView) {
    return buildMeterViewCardsData(chartData, chartView);
  }

  return buildTradeViewCardsData(chartData);
};

/**
 * Prepares the label for the card.
 * @param {*} intl
 * @param {object} cardDatum
 * @param {object} chartView
 * @returns {React.ReactElement} the heading label for the card.
 */
export const buildTradeViewCardLabel = (intl, cardDatum, chartView) => {
  const { aggregateBy, groupBy } = chartView;
  const {
    counterPartyUser, counterPartyProperty, property, tradeType,
  } = cardDatum;

  switch ([aggregateBy, groupBy].join('_')) {
    case [DATA_AGGREGATE_BY_PORTFOLIO, DATA_GROUP_BY_COUNTERPARTY].join('_'):
      if (counterPartyProperty) {
        return (
          <>
            {propertyLink({ id: counterPartyProperty.id, title: counterPartyProperty.title })}
            <div className="mt-2">{username(counterPartyUser)}</div>
          </>
        );
      }

      if (counterPartyUser) {
        return <div>{username(counterPartyUser)}</div>;
      }

      return <div>{getTradeTypeLabel(intl, tradeType)}</div>;
    case [DATA_AGGREGATE_BY_PORTFOLIO, DATA_GROUP_BY_TRADE_TYPE].join('_'):
      return <div>{getTradeTypeLabel(intl, tradeType)}</div>;
    case [DATA_AGGREGATE_BY_PROPERTY, DATA_GROUP_BY_COUNTERPARTY].join('_'):
      if (counterPartyProperty) {
        return (
          <>
            <CardsLabel>{propertyLink({ id: property?.id, title: property?.title })}</CardsLabel>
            {propertyLink({ id: counterPartyProperty.id, title: counterPartyProperty.title })}
            <div className="mt-2">{username(counterPartyUser)}</div>
          </>
        );
      }
      if (counterPartyUser) {
        return (
          <>
            <CardsLabel>{propertyLink({ id: property?.id, title: property?.title })}</CardsLabel>
            <div>{username(counterPartyUser)}</div>
          </>
        );
      }
      return (
        <>
          <CardsLabel>{propertyLink({ id: property?.id, title: property?.title })}</CardsLabel>
          <div>{getTradeTypeLabel(intl, tradeType)}</div>
        </>
      );
    case [DATA_AGGREGATE_BY_PROPERTY, DATA_GROUP_BY_TRADE_TYPE].join('_'):
      return (
        <>
          <CardsLabel>{propertyLink({ id: property?.id, title: property?.title })}</CardsLabel>
          {getTradeTypeLabel(intl, tradeType)}
        </>
      );
    default:
      console.error('invalid aggregate by and group by combination');
      return null;
  }
};
