/* eslint-disable react/jsx-props-no-spreading */

import {
  Redirect, Route, makeRouteConfig,
} from 'found';
import { DateTime, Duration } from 'luxon';
import React from 'react';
import { graphql } from 'react-relay';

// Admin
import {
  AdminCommunityList,
  AdminCommunityShow,
  AdminMeterList,
  AdminMeterShow,
  AdminMeterTradeRules,
  AdminPrimaryBillingPointList,
  AdminPrimaryBillingPointShow,
  AdminPropertyList,
  AdminPropertyShow,
  AdminPropertyUserAdd,
  AdminPropertyUserList,
  AdminPropertyUserShow,
  AdminStandingChargeList,
  AdminStandingChargeShow,
  AdminTariffList,
  AdminTariffShow,
  AdminTradeRuleList,
  AdminTradeRuleShow,
  AdminUserInviteAdmin,
  AdminUserInviteCustomer,
  AdminUserList,
  AdminUserShow,
  AdminVolumeChargeList,
  AdminVolumeChargeShow,
} from 'src/components/Admin';
// Authentication
import {
  AuthConfirmEmail,
  AuthForgotPassword,
  AuthLogin,
  AuthLogout,
  AuthRegister,
  AuthResetPassword,
} from 'src/components/Auth';
// HTTP404 errors.
import HTTP404 from 'src/components/HTTP404';
// Layouts
import AdminLayout from 'src/components/Layout/AdminLayout';
import AnonymousLayout from 'src/components/Layout/AnonymousLayout';
import CustomerLayout from 'src/components/Layout/CustomerLayout';
import CustomerPortfolioLayout from 'src/components/Layout/CustomerLayout/CustomerPortfolioLayout';
import CustomerPropertyLayout from 'src/components/Layout/CustomerLayout/CustomerPropertyLayout';
// Loading
import Loading from 'src/components/Loading';
// Portfolio
import { PortfolioList, PortfolioShow } from 'src/components/Portfolio';
import PortfolioPropertyMemberList from 'src/components/Portfolio/PortfolioShow/PortfolioPropertyMemberList';
import PortfolioUserMemberList from 'src/components/Portfolio/PortfolioShow/PortfolioUserMemberList';
// Profile
import { ProfileSettings } from 'src/components/Profile';
// Property
import {
  PropertyList,
  PropertySettings,
  PropertyShow,
} from 'src/components/Property';
import { TariffList } from 'src/components/Tariff';
// Trades
import { TradeList } from 'src/components/Trade';
import {
  TradeRuleActive,
  TradeRuleCustomerProposals,
  TradeRuleCustomerShow,
  TradeRuleList,
  TradeRulePrioritizeNominated,
  TradeRuleProposeNominatedFlat,
  TradeRuleProposeNominatedTimeOfUse,
  TradeRuleSetCommunityFlat,
  TradeRuleSetCommunityTimeOfUse,
  TradeRuleShow,
} from 'src/components/TradeRule';
// Config.
import { APIConfig } from 'src/config';
import { INHIBIT_PORTFOLIO_VIEWS, TradeRuleStates, TradeTypes } from 'src/util/constants';
import { dateToTimeInTimezone } from 'src/util/time';

import AuthenticatedRoute from './AuthenticatedRoute';

export default makeRouteConfig(
  <>
    <Route path="/home" Component={AnonymousLayout} />

    <Route path="/confirm-email" Component={AnonymousLayout}>
      <Route path=":token" Component={AuthConfirmEmail} />
    </Route>

    <Route path="/login" Component={AnonymousLayout}>
      <Route Component={AuthLogin} />
    </Route>

    <Route path="/logout" Component={AnonymousLayout}>
      <Route Component={AuthLogout} />
    </Route>

    <Route path="/forgot-password" Component={AnonymousLayout}>
      <Route Component={AuthForgotPassword} />
    </Route>

    <Route path="/reset-password" Component={AnonymousLayout}>
      <Route path=":token" Component={AuthResetPassword} />
    </Route>

    <Route path="/register" Component={AnonymousLayout}>
      <Route path=":token" Component={AuthRegister} />
    </Route>

    {/* TODO: admin layouts! */}
    <AuthenticatedRoute
      path="/admin"
      render={({ props }) => (props ? <AdminLayout {...props} /> : <Loading />)}
      query={graphql`
        query routes_AdminRoot_Query {
          viewer {
            ...AdminLayout_viewer
          }
        }
      `}
    >
      <Redirect from="" to="/admin/properties" />
      <Route path="communities">
        <Route
          render={({ props }) => (props ? <AdminCommunityList {...props} /> : <Loading />)}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_AdminCommunityList_Query(
              $count: Int
              $cursor: String
            ) {
              viewer {
                ...AdminCommunityList_viewer
              }
            }
          `}
        />
        <Route
          path=":id"
          render={({ props }) => (props ? <AdminCommunityShow {...props} /> : <Loading />)}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_AdminCommunityShow_Query(
              $id: ID!
              $count: Int
              $cursor: String
            ) {
              community(id: $id) {
                ...AdminCommunityShow_community
              }
            }
          `}
        />
      </Route>
      <Route path="meters">
        <Route
          render={({ props }) => (props ? <AdminMeterList {...props} /> : <Loading />)}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_AdminMeterList_Query($count: Int, $cursor: String) {
              viewer {
                ...AdminMeterList_viewer
              }
            }
          `}
        />
        <Route
          path=":id"
        >
          <Route
            render={({ props }) => (props ? <AdminMeterShow {...props} /> : <Loading />)}
            query={graphql`
              query routes_AdminMeterShow_Query($id: ID!) {
                meter(id: $id) {
                  ...AdminMeterShow_meter
                }
              }
            `}
          />
          <Route
            path="trade-rules"
            render={
              ({ props, variables }) => (
                props ? <AdminMeterTradeRules {...props} variables={variables} /> : <Loading />
              )
            }
            prepareVariables={(params, { location }) => {
              const { state, type, date } = location.query;

              const variables = { ...params, first: 50 };

              if (state && TradeRuleStates.includes(state)) {
                variables.state = state;
              }
              if (type && TradeTypes.includes(type)) {
                variables.type = type;
              }
              if (date) {
                const d = DateTime.fromISO(date);
                if (d.isValid) {
                  variables.start = d.startOf('day').toSeconds();
                  variables.finish = d.startOf('day').plus({ days: 1 }).toSeconds();
                }
              }

              return variables;
            }}
            query={graphql`
              query routes_AdminMeterTradeRules_Query(
                $id: ID!
                $count: Int
                $cursor: String
                $state: TradeRuleState
                $type: TradeType
                $start: Timestamp
                $finish: Timestamp
              ) {
                meter(id: $id) {
                  ...AdminMeterTradeRules_meter
                }
              }
            `}
          />
        </Route>
      </Route>
      <Route path="primary-billing-points">
        <Route
          render={
            ({ props }) => (props ? <AdminPrimaryBillingPointList {...props} /> : <Loading />)
          }
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_AdminPrimaryBillingPointList_Query(
              $count: Int
              $cursor: String
            ) {
              viewer {
                ...AdminPrimaryBillingPointList_viewer
              }
            }
          `}
        />
        <Route
          path=":id"
          render={
            ({ props }) => (props ? <AdminPrimaryBillingPointShow {...props} /> : <Loading />)
          }
          query={graphql`
            query routes_AdminPrimaryBillingPointShow_Query($id: ID!) {
              primaryBillingPoint(id: $id) {
                ...AdminPrimaryBillingPointShow_primaryBillingPoint
              }
            }
          `}
        />
      </Route>
      <Route path="properties">
        <Route
          render={({ props }) => (props ? <AdminPropertyList {...props} /> : <Loading />)}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_AdminPropertyList_Query($count: Int, $cursor: String) {
              viewer {
                ...AdminPropertyList_viewer
              }
            }
          `}
        />
        <Route
          path=":id"
          render={({ props }) => (props ? <AdminPropertyShow {...props} /> : <Loading />)}
          query={graphql`
            query routes_AdminPropertyShow_Query($id: ID!) {
              property(id: $id) {
                ...AdminPropertyShow_property
              }
            }
          `}
        />
      </Route>
      <Route path="property-users">
        <Route
          render={({ props }) => (props ? <AdminPropertyUserList {...props} /> : <Loading />)}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_AdminPropertyUserList_Query(
              $count: Int
              $cursor: String
            ) {
              viewer {
                ...AdminPropertyUserList_viewer
              }
            }
          `}
        />
        <Route path="new">
          <Route
            path=""
            render={({ props }) => (props ? <AdminPropertyUserAdd {...props} /> : <Loading />)}
            prepareVariables={(params) => ({ ...params, count: 500 })}
            query={graphql`
              query routes_AdminPropertyUserAdd_Query(
                $count: Int
                $cursor: String
              ) {
                viewer {
                  ...AdminPropertyUserAdd_viewer
                }
              }
            `}
          />
        </Route>
        <Route
          path=":id"
          render={({ props }) => (props ? <AdminPropertyUserShow {...props} /> : <Loading />)}
          query={graphql`
            query routes_AdminPropertyUserShow_Query($id: ID!) {
              propertyUser(id: $id) {
                ...AdminPropertyUserShow_propertyUser
              }
            }
          `}
        />
      </Route>
      <Route path="standing-charges">
        <Route
          render={({ props }) => (props ? <AdminStandingChargeList {...props} /> : <Loading />)}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_AdminStandingChargeList_Query(
              $count: Int
              $cursor: String
            ) {
              viewer {
                ...AdminStandingChargeList_viewer
              }
            }
          `}
        />
        <Route
          path=":id"
          render={({ props }) => (props ? <AdminStandingChargeShow {...props} /> : <Loading />)}
          query={graphql`
            query routes_AdminStandingChargeShow_Query($id: ID!) {
              standingCharge(id: $id) {
                ...AdminStandingChargeShow_standingCharge
              }
            }
          `}
        />
      </Route>
      <Route path="tariffs">
        <Route
          render={({ props }) => (props ? <AdminTariffList {...props} /> : <Loading />)}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_AdminTariffList_Query($count: Int, $cursor: String) {
              viewer {
                ...AdminTariffList_viewer
              }
            }
          `}
        />
        <Route
          path=":id"
          render={({ props }) => (props ? <AdminTariffShow {...props} /> : <Loading />)}
          query={graphql`
            query routes_AdminTariffShow_Query($id: ID!) {
              tariff(id: $id) {
                ...AdminTariffShow_tariff
              }
            }
          `}
        />
      </Route>
      <Route path="trade-rules">
        <Route
          render={
            ({ props, variables }) => (
              props ? <AdminTradeRuleList {...props} variables={variables} /> : <Loading />
            )
          }
          prepareVariables={(params, { location }) => {
            const { state, type, date } = location.query;

            const variables = { ...params, first: 50 };

            if (state && TradeRuleStates.includes(state)) {
              variables.state = state;
            }
            if (type && TradeTypes.includes(type)) {
              variables.type = type;
            }
            if (date) {
              const d = DateTime.fromISO(date);
              if (d.isValid) {
                variables.start = d.startOf('day').toSeconds();
                variables.finish = d.startOf('day').plus({ days: 1 }).toSeconds();
              }
            }

            return variables;
          }}
          query={graphql`
            query routes_AdminTradeRuleList_Query(
              $count: Int
              $cursor: String
              $state: TradeRuleState
              $type: TradeType
              $start: Timestamp
              $finish: Timestamp
            ) {
              viewer {
                ...AdminTradeRuleList_viewer
              }
            }
          `}
        />
        <Route
          path=":id"
          render={({ props }) => (props ? <AdminTradeRuleShow {...props} /> : <Loading />)}
          query={graphql`
            query routes_AdminTradeRuleShow_Query($id: ID!) {
              rule(id: $id) {
                ...AdminTradeRuleShow_rule
              }
            }
          `}
        />
      </Route>
      <Route path="users">
        <Route
          render={({ props }) => (props ? <AdminUserList {...props} /> : <Loading />)}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_AdminUserList_Query($count: Int, $cursor: String) {
              viewer {
                ...AdminUserList_viewer
              }
            }
          `}
        />
        <Route
          path=":id"
          render={({ props }) => (props ? <AdminUserShow {...props} /> : <Loading />)}
          query={graphql`
            query routes_AdminUserShow_Query($id: ID!) {
              user(id: $id) {
                ...AdminUserShow_user
              }
            }
          `}
        />
        <Route path="invite">
          <Route
            path="admin"
            Component={AdminUserInviteAdmin}
          />
          <Route
            path="customer"
            Component={AdminUserInviteCustomer}
          />
        </Route>
      </Route>
      <Route path="volume-charges">
        <Route
          render={({ props }) => (props ? <AdminVolumeChargeList {...props} /> : <Loading />)}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_AdminVolumeChargeList_Query(
              $count: Int
              $cursor: String
            ) {
              viewer {
                ...AdminVolumeChargeList_viewer
              }
            }
          `}
        />
        <Route
          path=":id"
          render={({ props }) => (props ? <AdminVolumeChargeShow {...props} /> : <Loading />)}
          query={graphql`
            query routes_AdminVolumeChargeShow_Query($id: ID!) {
              volumeCharge(id: $id) {
                ...AdminVolumeChargeShow_volumeCharge
              }
            }
          `}
        />
      </Route>
    </AuthenticatedRoute>

    <AuthenticatedRoute
      path="/"
      Component={CustomerLayout}
      query={graphql`
        query routes_Root_Query {
          viewer {
            id viewerUser {
              id,
              type
            }
            ...CustomerLayout_viewer
          }
        }
      `}
      prepareVariables={(params, _others) => ({ ...params })}
    >

      <Redirect from="" to="/properties" />

      <Route path="profile">
        <Redirect from="" to="/profile/settings" />
        <Route
          path="settings"
          Component={ProfileSettings}
          query={graphql`
            query routes_ProfileSettings_Query {
              viewer {
                ...ProfileSettings_viewer
              }
            }
          `}
          render={({ props }) => (props ? <ProfileSettings {...props} /> : <Loading />)}
        />
      </Route>

      <Route path="properties">
        <Route
          Component={PropertyList}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_PropertyList_Query($count: Int, $cursor: String) {
              viewer {
                ...PropertyList_viewer
              }
            }
          `}
          render={({ props }) => (props ? <PropertyList {...props} /> : <Loading />)}
        />
      </Route>
      <Route path="portfolios">
        <Route
          Component={PortfolioList}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_PortfolioList_Query($count: Int, $cursor: String) {
              viewer {
                ...PortfolioList_viewer @arguments(count: $count, cursor: $cursor)
              }
            }
          `}
          render={({ props }) => {
            const inhibitPortfolioViews = APIConfig().feature(INHIBIT_PORTFOLIO_VIEWS);

            if (!props) {
              return <Loading />;
            }

            if (props && inhibitPortfolioViews) {
              return <HTTP404 />;
            }

            return <PortfolioList {...props} />;
          }}
        />
      </Route>

      <Route path="trade-rules">
        <Route
          Component={TradeRuleCustomerProposals}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_TradeRuleCustomerProposals_Query {
              viewer {
                ...TradeRuleCustomerProposals_viewer
              }
            }
          `}
          render={({ props }) => (props ? <TradeRuleCustomerProposals {...props} /> : <Loading />)}
        />

        <Route
          path=":id"
          Component={TradeRuleCustomerShow}
          prepareVariables={(params) => ({ ...params, first: 50 })}
          query={graphql`
            query routes_TradeRuleCustomerShow_Query($id: ID!) {
              viewer {
                ...TradeRuleCustomerShow_viewer @arguments(id: $id)
              }
            }
          `}
          render={({ props }) => (props ? <TradeRuleCustomerShow {...props} /> : <Loading />)}
        />
      </Route>
    </AuthenticatedRoute>

    <AuthenticatedRoute
      path="properties/:id"
      Component={CustomerPropertyLayout}
      query={graphql`
        query routes_CustomerPropertyRoot_Query($id: ID!) {
          viewer {
            ...CustomerPropertyLayout_viewer
          }
          property(id: $id) {
            ...CustomerPropertyLayout_property
          }
        }
      `}
      prepareVariables={(params, _others) => ({ ...params })}
      render={({ props }) => (props ? <CustomerPropertyLayout {...props} /> : <Loading />)}
    >
      <Route
        path=""
        Component={PropertyShow}
        query={graphql`
          query routes_PropertyShow_Query(
            $id: ID!
            $start: Timestamp!
            $finish: Timestamp!
            $timeZone: String!
            $historianAggregation: String!
            $tradeAggregation: String!
          ) {
              ...PropertyShow
                @arguments(
                  id: $id
                  start: $start
                  finish: $finish
                  timeZone: $timeZone
                  historianAggregation: $historianAggregation
                  tradeAggregation: $tradeAggregation
                )
          }
        `}
        prepareVariables={(params, { location }) => {
          const {
            start, finish, tz,
          } = location.query;

          let timeZone = APIConfig().DEFAULT_TIMEZONE;
          if (tz && tz !== undefined) {
            timeZone = tz;
          }

          const now = DateTime.now();

          let s = dateToTimeInTimezone(now, timeZone).startOf('day').minus(Duration.fromISO('P1M1D'));
          let f = s.plus(Duration.fromISO('P1M1D'));

          if (start && start !== undefined) {
            const ts = DateTime.fromISO(start, { zone: timeZone });
            if (ts.invalidExplanation === null) {
              s = ts.startOf('day');
            }
          }

          if (finish && finish !== undefined) {
            const ts = DateTime.fromISO(finish, { zone: timeZone });
            if (ts.invalidExplanation === null) {
              f = ts.plus(Duration.fromISO('P1D')).startOf('day');
            }
          }

          const duration = f.diff(s);

          let agg = 'PT30M';
          if (duration.as('days') > 7) {
            agg = 'P1D';
          }

          return {
            ...params,
            start: s.toSeconds(),
            finish: f.toSeconds(),
            timeZone,
            historianAggregation: agg,
            tradeAggregation: agg,
          };
        }}
        render={({ props }) => (props ? <PropertyShow {...props} /> : <Loading />)}
      />
      <Route
        path="settings"
        Component={PropertySettings}
        query={graphql`
          query routes_PropertySettings_Query($id: ID!) {
            property(id: $id) {
              ...PropertySettings_property
            }
          }
        `}
        render={({ props }) => (props ? <PropertySettings {...props} /> : <Loading />)}
      />
      <Route
        path="tariffs"
        Component={TariffList}
        query={graphql`
          query routes_TariffList_Query($id: ID!) {
            property(id: $id) {
              ...TariffList_property
            }
          }
        `}
        render={({ props }) => (props ? <TariffList {...props} /> : <Loading />)}
      />
      <Route path="trade-rules">
        <Route
          path="active"
          Component={TradeRuleActive}
          query={graphql`
            query routes_TradeRuleActive_Query($id: ID!) {
              property(id: $id) {
                ...TradeRuleActive_property
              }
            }
          `}
          render={({ props }) => (props ? <TradeRuleActive {...props} /> : <Loading />)}
        />
        <Route path="propose/nominated">
          <Route
            path=""
            Component={TradeRuleProposeNominatedFlat}
            query={graphql`
              query routes_TradeRuleProposeNominatedFlat_Query($id: ID!) {
                property(id: $id) {
                  ...TradeRuleProposeNominatedFlat_property
                }
              }
            `}
            render={
              ({ props }) => (
                props ? <TradeRuleProposeNominatedFlat {...props} /> : <Loading />
              )
            }
          />
          <Route
            path="time-of-use"
            Component={TradeRuleProposeNominatedTimeOfUse}
            prepareVariables={(params) => ({
              ...params,
              now: Math.floor(DateTime.local().toSeconds()),
            })}
            query={graphql`
              query routes_TradeRuleProposeNominatedTimeOfUse_Query($id: ID!, $now: Timestamp!) {
                property(id: $id) {
                  ...TradeRuleProposeNominatedTimeOfUse_property
                }
              }
            `}
            render={
              ({ props }) => (
                props ? <TradeRuleProposeNominatedTimeOfUse {...props} /> : <Loading />
              )
            }
          />
        </Route>
        <Route
          path=""
          Component={TradeRuleList}
          query={graphql`
            query routes_TradeRuleList_Query($id: ID!) {
              property(id: $id) {
                ...TradeRuleList_property
              }
            }
          `}
          render={({ props }) => (props ? <TradeRuleList {...props} /> : <Loading />)}
        />
        <Route
          path=":ruleId"
          Component={TradeRuleShow}
          query={graphql`
            query routes_TradeRuleShow_Query($id: ID!, $ruleId: ID!) {
              property(id: $id) {
                ...TradeRuleShow_property
              }
              rule(id: $ruleId) {
                ...TradeRuleShow_rule
              }
            }
          `}
          render={({ props }) => (props ? <TradeRuleShow {...props} /> : <Loading />)}
        />
      </Route>
      <Route path="meters/:meterId/trade-rules">
        <Route
          path="nominated/prioritize"
          Component={TradeRulePrioritizeNominated}
          query={graphql`
            query routes_TradeRulePrioritizeNominated_Query($id: ID!, $meterId: ID!) {
              property(id: $id) {
                ...TradeRulePrioritizeNominated_property
              }
              meter(id: $meterId) {
                ...TradeRulePrioritizeNominated_meter
              }
            }
          `}
          render={
            ({ props }) => (props ? <TradeRulePrioritizeNominated {...props} /> : <Loading />)
          }
        />
        <Route path="community/set">
          <Route
            path=""
            Component={TradeRuleSetCommunityFlat}
            query={graphql`
              query routes_TradeRuleSetCommunityFlat_Query($id: ID!, $meterId: ID!) {
                property(id: $id) {
                  ...TradeRuleSetCommunityFlat_property
                }
                meter(id: $meterId) {
                  ...TradeRuleSetCommunityFlat_meter
                }
              }
            `}
            render={({ props }) => (props ? <TradeRuleSetCommunityFlat {...props} /> : <Loading />)}
          />
          <Route
            path="time-of-use"
            Component={TradeRuleSetCommunityTimeOfUse}
            prepareVariables={(params) => ({
              ...params,
              now: Math.floor(DateTime.local().toSeconds()),
            })}
            query={graphql`
              query routes_TradeRuleSetCommunityTimeOfUse_Query($id: ID!, $meterId: ID!, $now: Timestamp!) {
                property(id: $id) {
                  ...TradeRuleSetCommunityTimeOfUse_property
                }
                meter(id: $meterId) {
                  ...TradeRuleSetCommunityTimeOfUse_meter
                }
              }
            `}
            render={
              ({ props }) => (props ? <TradeRuleSetCommunityTimeOfUse {...props} /> : <Loading />)
            }
          />
        </Route>
      </Route>
      <Route path="trades">
        <Route
          path="history"
          Component={TradeList}
          query={graphql`
            query routes_TradeList_Query(
              $id: ID!
              $start: Timestamp!
              $finish: Timestamp!
            ) {
                ...TradeList
                  @arguments(id: $id, start: $start, finish: $finish)
            }
          `}
          prepareVariables={(params, { location }) => {
            const { date } = location.query;

            let d = DateTime.local()
              .startOf('day')
              .minus(Duration.fromISO('P1D'));

            if (date && date !== undefined) {
              const ts = DateTime.fromISO(date);
              if (ts.invalidExplanation === null) {
                d = ts;
              }
            }

            return {
              ...params,
              start: d.toSeconds(),
              finish: d.plus(Duration.fromISO('P1D')).toSeconds(),
            };
          }}
          render={({ props }) => (props ? <TradeList {...props} /> : <Loading />)}
        />
      </Route>
    </AuthenticatedRoute>

    <AuthenticatedRoute
      path="portfolios/:id"
      Component={CustomerPortfolioLayout}
      query={graphql`
        query routes_CustomerPortfolioRoot_Query($id: ID!) {
          viewer {
            ...CustomerPortfolioLayout_viewer
          }
          portfolio(id: $id) {
            ...CustomerPortfolioLayout_portfolio
          }
        }
      `}
      prepareVariables={(params, _others) => ({ ...params })}
      render={({ props }) => (props ? <CustomerPortfolioLayout {...props} /> : <Loading />)}
    >
      <Route
        path=""
        Component={PortfolioShow}
        query={graphql`
          query routes_PortfolioShow_Query(
            $id: ID!
            $start: Timestamp!
            $finish: Timestamp!
            $timeZone: String!
            $historianAggregation: String!
            $tradeAggregation: String!
          ) {
              ...PortfolioShow
                @arguments(
                  id: $id
                  start: $start
                  finish: $finish
                  timeZone: $timeZone
                  historianAggregation: $historianAggregation
                  tradeAggregation: $tradeAggregation
                )
          }
        `}
        prepareVariables={(params, { location }) => {
          const {
            start, finish, tz,
          } = location.query;

          let timeZone = APIConfig().DEFAULT_TIMEZONE;
          if (tz && tz !== undefined) {
            timeZone = tz;
          }

          let s = DateTime.local({ zone: timeZone })
            .startOf('day')
            .minus(Duration.fromISO('P1M1D'));
          let f = s.plus(Duration.fromISO('P1M1D'));

          if (start && start !== undefined) {
            const ts = DateTime.fromISO(start);
            if (ts.invalidExplanation === null) {
              s = ts.startOf('day');
            }
          }

          if (finish && finish !== undefined) {
            const ts = DateTime.fromISO(finish);
            if (ts.invalidExplanation === null) {
              f = ts.plus(Duration.fromISO('P1D')).startOf('day');
            }
          }

          const duration = f.diff(s);

          let agg = 'PT30M';
          if (duration.as('days') > 7) {
            agg = 'P1D';
          }

          return {
            ...params,
            start: s.toSeconds(),
            finish: f.toSeconds(),
            timeZone,
            historianAggregation: agg,
            tradeAggregation: agg,
          };
        }}
        render={({ props }) => {
          const inhibitPortfolioViews = APIConfig().feature(INHIBIT_PORTFOLIO_VIEWS);

          if (!props) {
            return <Loading />;
          }

          if (props && inhibitPortfolioViews) {
            return <HTTP404 />;
          }

          return <PortfolioShow {...props} />;
        }}
      />
      <Route
        path="properties"
        Component={PortfolioPropertyMemberList}
        prepareVariables={(params, { location }) => {
          const { start, finish } = location.query;
          const timeZone = APIConfig().DEFAULT_TIMEZONE;
          let s = DateTime.local({ zone: timeZone })
            .startOf('day')
            .minus(Duration.fromISO('P1M1D'));
          let f = s.plus(Duration.fromISO('P1M1D'));

          if (start && start !== undefined) {
            const ts = DateTime.fromISO(start);
            if (ts.invalidExplanation === null) {
              s = ts.startOf('day');
            }
          }

          if (finish && finish !== undefined) {
            const ts = DateTime.fromISO(finish);
            if (ts.invalidExplanation === null) {
              f = ts.plus(Duration.fromISO('P1D')).startOf('day');
            }
          }

          return {
            ...params,
            count: 100,
            start: s.toSeconds(),
            finish: f.toSeconds(),
          };
        }}
        query={graphql`
        query routes_PortfolioPropertyMemberList_Query(
          $id: ID!
          $count: Int,
          $cursor: String
          $start: Timestamp!
          $finish: Timestamp!
        ) {
          viewer{
            ...PortfolioPropertyMemberList_viewer
              @arguments(id: $id, count: $count, cursor: $cursor, start: $start, finish: $finish)
            }
        }
      `}
        render={({ props }) => (props ? <PortfolioPropertyMemberList {...props} /> : <Loading />)}
      />
      <Route
        path="users"
        Component={PortfolioUserMemberList}
        prepareVariables={(params, { location }) => {
          const { start, finish } = location.query;
          const timeZone = APIConfig().DEFAULT_TIMEZONE;
          let s = DateTime.local({ zone: timeZone })
            .startOf('day')
            .minus(Duration.fromISO('P1M1D'));
          let f = s.plus(Duration.fromISO('P1M1D'));

          if (start && start !== undefined) {
            const ts = DateTime.fromISO(start);
            if (ts.invalidExplanation === null) {
              s = ts.startOf('day');
            }
          }

          if (finish && finish !== undefined) {
            const ts = DateTime.fromISO(finish);
            if (ts.invalidExplanation === null) {
              f = ts.plus(Duration.fromISO('P1D')).startOf('day');
            }
          }

          return {
            ...params,
            count: 100,
            start: s.toSeconds(),
            finish: f.toSeconds(),
          };
        }}
        query={graphql`
            query routes_PortfolioUserMembersList_Query(
              $id: ID!,
              $count: Int,
              $cursor: String
              $start: Timestamp!
              $finish: Timestamp!
              ) {
                viewer {
                ...PortfolioUserMemberList_viewer @arguments(id: $id, count: $count, cursor: $cursor, start: $start, finish: $finish)
                }
            }
        `}
        render={({ props }) => (props ? <PortfolioUserMemberList {...props} /> : <Loading />)}
      />
    </AuthenticatedRoute>
  </>,
);
