import Link from 'found/Link';
import { DateTime } from 'luxon';
import React from 'react';

import UUID from 'src/components/UUID';
import {
  DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE,
} from 'src/util/constants';

import { dateRangeToTimeRange } from '../time';

/**
 * Renders the label for the meter data cards (i.e aggregated by meter) in the portfolio dashboard.
 * @param {object} obj contains id and title.
 * @param {string} obj.id - The property's id.
 * @param {string} obj.title - The property's title
 * @param {string} obj.start The start of the time range on the property dashboard.
 * @param {string} obj.finish The finish of the time range on the property dashboard.
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} obj.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} obj.groupBy
 * @returns {React.ReactElement | null} - The rendered meter content property label.
 */
export const propertyLink = ({
  id, title, start, finish, aggregateBy, groupBy,
}) => {
  if (!id) {
    return null;
  }

  const label = title || <UUID uuid={id} />;
  const to = { pathname: `/properties/${id}`, query: {} };

  if (start) { to.query.start = start; }
  if (finish) { to.query.finish = finish; }
  if (aggregateBy) { to.query.aggregateBy = aggregateBy; }
  if (groupBy) { to.query.groupBy = groupBy; }

  return (
    <Link to={to}>{label}</Link>
  );
};

/**
 * Renders the label a property's trade rule.
 * @param {string} propertyId the property's id.
 * @param {string} tradeRuleId the id of the trade rule at the property.
 * @param {string} label a label to use for the link.
 * @returns {React.ReactElement | null} - The rendered meter content property label.
 */
export const propertyTradeRuleLink = (propertyId, tradeRuleId, label) => {
  if (!propertyId || !tradeRuleId) {
    return null;
  }

  return (
    <Link to={`/properties/${propertyId}/trade-rules/${tradeRuleId}`}>{label}</Link>
  );
};

/**
 * Renders the label for portfolio's property members.
 * @param {object} portfolio the portfolio object.
 * @param {string} portfolio.id the portfolio's id.
 * @param {object} portfolio.timespan the portfolio's timespan.
 * @param {DateTime} portfolio.timespan.start the portfolio's timespan start.
 * @param {DateTime} portfolio.timespan.finish the portfolio's timespan finish.
 * @param {string} label a label to use for the link.
 * @returns {React.ReactElement | null} - The rendered portfolio's property members label.
 */
export const portfolioPropertiesLink = (portfolio, label) => {
  const { id, timespan } = portfolio || {};
  if (!id) {
    return null;
  }
  const pathname = `/portfolios/${id}/properties`;
  const query = {};

  if (timespan) {
    const { start: startTime, finish: finishTime } = dateRangeToTimeRange(
      timespan,
    );

    query.start = startTime.toISODate();
    query.finish = finishTime.minus({ days: 1 }).toISODate();
  }

  return (
    <Link to={{
      pathname,
      query,
    }}
    >
      {label}
    </Link>
  );
};

/**
 * Renders the label for portfolio's user members.
 * @param {object} portfolio the portfolio object.
 * @param {string} portfolio.id the portfolio's id.
 * @param {object} portfolio.timespan the portfolio's timespan.
 * @param {DateTime} portfolio.timespan.start the portfolio's timespan start.
 * @param {DateTime} portfolio.timespan.finish the portfolio's timespan finish.
 * @param {string} label a label to use for the link.
 * @returns {React.ReactElement | null} - The rendered portfolio's user members label.
 */
export const portfolioUsersLink = (portfolio, label) => {
  const { id, timespan } = portfolio || {};
  if (!id) {
    return null;
  }
  const pathname = `/portfolios/${id}/users`;
  const query = {};

  if (timespan) {
    const { start: startTime, finish: finishTime } = dateRangeToTimeRange(
      timespan,
    );

    query.start = startTime.toISODate();
    query.finish = finishTime.minus({ days: 1 }).toISODate();
  }

  return (
    <Link to={{
      pathname,
      query,
    }}
    >
      {label}
    </Link>
  );
};
